/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto } from '@SLR/marketplaceService-sdk';
import { SLRModal, useGetOrganization } from '@SLR/shared-library';
import {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ContactModal, DeclarationsModal, OrganizationDetail, Price } from '..';

import './EntryModal.scss';

export const EntryModalContentTypes = {
  OrganizationDetail: 'organizationDetail',
  Contact: 'contact',
  Price: 'price',
  Declaration: 'declaration',
  None: ''
} as const;

export type EntryModalContentType =
  (typeof EntryModalContentTypes)[keyof typeof EntryModalContentTypes];

export type EntryModalProps = {
  entry?: Nullable<EntryDto>;
  contentType: EntryModalContentType;
  onChange: Dispatch<SetStateAction<EntryModalContentType>>;
  onClose: () => void;
};

export const EntryModal: FC<EntryModalProps> = ({
  entry,
  contentType,
  onChange,
  onClose
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail'
  });

  const { data: organization } = useGetOrganization(entry?.organizationId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const modal: { title: string; content: ReactElement } | undefined =
    useMemo(() => {
      if (contentType === EntryModalContentTypes.OrganizationDetail) {
        return {
          title: organization?.name ?? '',
          content: (
            <OrganizationDetail
              onChange={onChange}
              organization={organization}
            />
          )
        };
      }
      if (contentType === EntryModalContentTypes.Contact) {
        return {
          title: t('contactUs'),
          content: (
            <ContactModal
              onLoading={setIsLoading}
              onChange={onChange}
              entry={entry}
              organizationName={organization?.name}
            />
          )
        };
      }
      if (contentType === EntryModalContentTypes.Price) {
        return {
          title: t('price.priceInformation'),
          content: <Price entry={entry} />
        };
      }
      if (contentType === EntryModalContentTypes.Declaration) {
        return {
          title: t('declarations.modal.title'),
          content: <DeclarationsModal />
        };
      }
    }, [contentType, entry, onChange, organization, t]);

  return (
    <SLRModal
      isLoading={isLoading}
      backdrop={
        contentType === EntryModalContentTypes.Contact ? 'static' : true
      }
      title={modal?.title}
      isOpen={contentType !== EntryModalContentTypes.None}
      onHide={onClose}
      onClose={onClose}
      size="xl"
      fullscreen="md-down"
      className="entry-modal"
      backdropClassName="entry-modal-backdrop"
    >
      <div className="p-0 p-sm-4">{modal?.content}</div>
    </SLRModal>
  );
};
