/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John,
 * Emely Janke
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { EntryDto, EntryDtoStatusEnum } from '@SLR/marketplaceService-sdk';
import {
  getIcon,
  hasValue,
  isEmptyOrNull,
  showErrorToast,
  showSuccessToast,
  SLRAccordion,
  SLRPageHead,
  SLRPrompt,
  SLRPromptLabels,
  SLRPromptProps,
  SLRSpinner,
  toDayMonthYear,
  toHourAndMinute,
  useDeleteMediaItem,
  useToaster,
  useUpdateMediaItems
} from '@SLR/shared-library';
import { IconProp, RotateProp } from '@fortawesome/fontawesome-svg-core';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorView } from 'components';
import {
  Declarations,
  MARKETPLACE_MANAGEMENT_PATH,
  MARKETPLACE_REVIEW_PATH,
  TERMS_OF_USE_PATH
} from 'configs';
import {
  useCreateEntry,
  useDeleteEntry,
  useGetEntry,
  usePublishEntry,
  useSubmitEntry,
  useUnPublishEntry,
  useUpdateEntry
} from 'hooks';
import { difference } from 'lodash';
import { useMarketplace, useOrganization } from 'providers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { default as ReactRouterPrompt } from 'react-router-prompt';
import {
  accordionProps,
  Actions,
  EntryInput,
  EntryInputSchema,
  EntryPreview,
  FormKeys,
  getCreateFormViews,
  getEntryMediaItems
} from '.';

import './EntryCreateUpdate.scss';

export const EntryCreateUpdate: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create'
  });
  const { isReviewContext } = useMarketplace();
  const { isOrganizationChangeNavigate, selectedOrganizationId } =
    useOrganization();

  // #region Entry query definitions
  const { entryId } = useParams();

  // In case an entry is created the page is not updated thus entryId from useParams is still undefined.
  // To allow certain requests (e.g. delete and publish), refetch and make update instead of create request the state "entryFormId" is used
  const [newEntryId, setNewEntryId] = useState<string | undefined>(entryId);

  const { data: entry, error, isError, isPending } = useGetEntry(newEntryId);

  const { mutate: createEntry, isPending: isCreateEntryLoading } =
    useCreateEntry();
  const { mutate: updateEntry, isPending: isUpdateEntryLoading } =
    useUpdateEntry();
  const { mutate: submitEntry, isPending: isSubmitEntryLoading } =
    useSubmitEntry();
  const { mutate: deleteEntry, isPending: isDeleteEntryLoading } =
    useDeleteEntry();
  const { mutate: publishEntry, isPending: isPublishEntryLoading } =
    usePublishEntry();
  const { mutate: unPublishEntry, isPending: isUnPublishEntryLoading } =
    useUnPublishEntry();

  const { mutate: updateMedia } = useUpdateMediaItems({
    onDeleteError: () => showErrorToast('toasts.deleteMediaItem.error'),
    onSaveError: () => showErrorToast('toasts.saveMediaItem.error')
  });
  const { mutate: deleteMedia } = useDeleteMediaItem(() =>
    showErrorToast('toasts.deleteMediaItem.error')
  );
  // #endregion

  // #region Form initialization and validation
  const form = useForm<EntryInput>({
    // Allow null values
    // eslint-disable-next-line
    // @ts-expect-error
    values: {
      ...entry,
      organizationId: entry?.organizationId ?? selectedOrganizationId,
      declarations: Object.assign(
        {},
        ...Object.values(Declarations).map((declaration) => ({
          [declaration]: Boolean(
            entry?.declarations && entry.declarations[declaration]
          )
        }))
      )
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(EntryInputSchema)
  });

  const createFormViews = getCreateFormViews(form.formState.errors);
  const [activeStepKey, setActiveStepKey] = useState<string>();

  useEffect(() => {
    if (isEmptyOrNull(activeStepKey))
      setActiveStepKey(FormKeys.BaseInformation);
  }, [activeStepKey, setActiveStepKey]);

  const { clearAllToasts } = useToaster();

  const showFormErrorToast = useCallback(() => {
    clearAllToasts();
    showErrorToast('toasts.schemaError');
  }, [clearAllToasts]);

  const isUpdated = hasValue(Object.keys(form.formState.dirtyFields));
  const isValid = !hasValue(Object.keys(form.formState.errors));

  // Depending the status of the entry, saving the entry should happen after a validation (function call is wrapped inside form.handleSubmit)
  const useValidatingSave = useMemo(
    () =>
      entry?.status === EntryDtoStatusEnum.InPrfung ||
      entry?.status === EntryDtoStatusEnum.BereitZumVerffentlichen ||
      entry?.status === EntryDtoStatusEnum.Verffentlicht,
    [entry?.status]
  );

  useEffect(() => {
    if (!isValid) showFormErrorToast();
  }, [form.formState.isSubmitting, isValid, showFormErrorToast]);

  const entryFormId = form.getValues('id');
  const organizationId = form.getValues('organizationId');

  // #endregion

  // #region Handling navigation and prompts
  const navigate = useNavigate();
  const { state } = useLocation();

  const [preventNavigation, setPreventNavigation] = useState<boolean>(false);
  const [isDeleteNavigate, setIsDeleteNavigate] = useState<boolean>(false);

  useEffect(
    () =>
      setPreventNavigation(
        !isOrganizationChangeNavigate && !isDeleteNavigate && isUpdated
      ),
    [
      isUpdated,
      isDeleteNavigate,
      isOrganizationChangeNavigate,
      setPreventNavigation
    ]
  );

  const [promptProps, setPromptProps] = useState<SLRPromptProps | null>(null);
  const handleHidePrompt = () => setPromptProps(null);

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const handleShowPreview = () => setShowPreview(true);
  const handleHidePreview = () => setShowPreview(false);

  const handleNavigate = useCallback(
    (confirm?: () => void) => {
      handleHidePrompt();
      handleHidePreview();

      if (confirm) confirm();
      navigate(
        state?.previousPath ??
          (isReviewContext
            ? `${MARKETPLACE_REVIEW_PATH}/${entryId}`
            : MARKETPLACE_MANAGEMENT_PATH),
        {
          replace: true
        }
      );
    },
    [entryId, isReviewContext, navigate, state?.previousPath]
  );

  const showSuccessPrompt = useCallback(
    (
      labels: SLRPromptLabels,
      icon: { symbol: IconProp; rotation?: RotateProp },
      shouldLeavePage?: boolean
    ) =>
      setPromptProps({
        type: 'success',
        labels,
        icon,
        onConfirm: () =>
          shouldLeavePage ? handleNavigate() : handleHidePrompt(),
        onCancel: () =>
          shouldLeavePage ? handleNavigate() : handleHidePrompt()
      }),
    [handleNavigate]
  );
  // #endregion

  // #region Handling entry requests
  const handleUpdateMedia = useCallback(
    (entryMediaItems: string[], oldMediaItems?: string[]) => {
      const deletedMedia = difference(oldMediaItems, entryMediaItems);
      updateMedia({ newMediaIds: entryMediaItems, oldMediaIds: deletedMedia });
    },
    [updateMedia]
  );

  const handleSaveEntry = useCallback(
    (callback?: () => void) => {
      if (!isReviewContext && isEmptyOrNull(organizationId)) return;

      // In case there is an update or the entry was not created yet, we first crate/update the entry before the callback or if user just clicks on the save button with no callback
      if (isUpdated || isEmptyOrNull(entryFormId) || !callback) {
        // Needed as form allows null values
        // eslint-disable-next-line
        // @ts-expect-error
        const entryDto: EntryDto = form.getValues();

        const entryMediaItems = getEntryMediaItems(entryDto);
        const oldMediaItems = getEntryMediaItems(entry);

        if (isEmptyOrNull(entryFormId)) {
          createEntry(
            { entryDto },
            {
              onSuccess: (id) => {
                form.setValue('id', id);
                handleUpdateMedia(entryMediaItems, oldMediaItems);

                if (!callback) {
                  showSuccessToast('toasts.entryCreate.success');
                  /**
                   * In case of create the only possible callback is "handleSubmitEntry"
                   * If newEntryId is updated here this will cause the form to rerender removing all existing errors
                   * Therefore for this case we do not update newEntryId allowing the user to see potential errors
                   */
                  setNewEntryId(id);
                } else {
                  callback();
                }
              }
            }
          );
        } else if (isUpdated) {
          updateEntry(
            { id: entryFormId, entryDto },
            {
              onSuccess: () => {
                handleUpdateMedia(entryMediaItems, oldMediaItems);

                if (!callback) {
                  showSuccessToast('toasts.entryUpdate.success');
                } else {
                  callback();
                }
              }
            }
          );
        } else showSuccessToast('toasts.entryUpdate.success');
      } else {
        callback();
      }
    },
    [
      createEntry,
      entry,
      entryFormId,
      form,
      handleUpdateMedia,
      isReviewContext,
      isUpdated,
      organizationId,
      updateEntry
    ]
  );

  const handleSubmitEntry = useCallback(
    (id: string | null) =>
      setPromptProps({
        labels: t('prompts.submit.confirm', {
          returnObjects: true
        }),
        isLoading: isSubmitEntryLoading,
        onConfirm: () => {
          if (!isEmptyOrNull(organizationId) && !isEmptyOrNull(id)) {
            submitEntry(
              { id, organizationId },
              {
                onSuccess: () => {
                  if (isEmptyOrNull(newEntryId)) setNewEntryId(id);
                  showSuccessPrompt(
                    t('prompts.submit.success', {
                      returnObjects: true
                    }) as SLRPromptLabels,
                    { symbol: getIcon('fat', 'envelope-open-text') },
                    true
                  );
                }
              }
            );
            handleHidePrompt();
          }
        },
        onCancel: handleHidePrompt
      }),
    [
      isSubmitEntryLoading,
      newEntryId,
      organizationId,
      showSuccessPrompt,
      submitEntry,
      t
    ]
  );

  const handlePublishEntry = useCallback(
    () =>
      setPromptProps({
        labels: {
          ...t('prompts.publish.confirm', {
            returnObjects: true
          }),
          checkboxLabel: (
            <Trans
              t={t}
              i18nKey="prompts.publish.checkboxLabel"
              components={[
                <Link
                  to={TERMS_OF_USE_PATH}
                  target="_blank"
                  key="TERMS_OF_USE_PATH"
                />
              ]}
            />
          )
        },
        isLoading: isPublishEntryLoading,
        onConfirm: () => {
          if (!isEmptyOrNull(organizationId) && !isEmptyOrNull(entryFormId)) {
            publishEntry(
              { id: entryFormId, organizationId },
              {
                onSuccess: () =>
                  showSuccessPrompt(
                    t('prompts.publish.success', {
                      returnObjects: true
                    }) as SLRPromptLabels,
                    { symbol: getIcon('fat', 'rocket-launch') },
                    true
                  )
              }
            );
            handleHidePrompt();
          }
        },
        onCancel: handleHidePrompt
      }),
    [
      entryFormId,
      isPublishEntryLoading,
      organizationId,
      publishEntry,
      showSuccessPrompt,
      t
    ]
  );

  const handleUnPublishEntry = useCallback(
    () =>
      setPromptProps({
        labels: t('prompts.unpublish.confirm', {
          returnObjects: true
        }),
        isLoading: isUnPublishEntryLoading,
        onConfirm: () => {
          if (!isEmptyOrNull(organizationId) && !isEmptyOrNull(entryFormId)) {
            unPublishEntry(
              { id: entryFormId, organizationId },
              {
                onSuccess: () =>
                  showSuccessPrompt(
                    t('prompts.unpublish.success', {
                      returnObjects: true
                    }) as SLRPromptLabels,
                    { symbol: getIcon('fat', 'rocket-launch'), rotation: 180 }
                  )
              }
            );
            handleHidePrompt();
          }
        },
        onCancel: handleHidePrompt
      }),
    [
      entryFormId,
      isUnPublishEntryLoading,
      organizationId,
      showSuccessPrompt,
      t,
      unPublishEntry
    ]
  );

  const handleDeleteEntry = useCallback(
    () =>
      setPromptProps({
        labels: t('prompts.delete.confirm', {
          returnObjects: true
        }),
        confirmButtonVariant: 'danger',
        isLoading: isDeleteEntryLoading,
        onConfirm: () => {
          const handleDeleteMedia = () =>
            deleteMedia(
              [
                ...getEntryMediaItems(form.getValues()),
                ...getEntryMediaItems(entry)
              ],
              {
                onSettled: () => handleNavigate()
              }
            );
          if (!isEmptyOrNull(organizationId) && !isEmptyOrNull(entryFormId)) {
            deleteEntry(
              { id: entryFormId, organizationId },
              {
                onSuccess: () => {
                  setIsDeleteNavigate(true);
                  handleDeleteMedia();
                }
              }
            );
          } else if (isEmptyOrNull(entryFormId)) {
            handleDeleteMedia();
          }
        },
        onCancel: () => {
          setIsDeleteNavigate(false);
          handleHidePrompt();
        }
      }),
    [
      t,
      isDeleteEntryLoading,
      organizationId,
      entryFormId,
      deleteEntry,
      deleteMedia,
      form,
      entry,
      handleNavigate
    ]
  );
  // #endregion

  if (!isEmptyOrNull(entryId) && isPending) return <SLRSpinner />;

  if (isError)
    return (
      <ErrorView
        showHomeButton
        homeButtonPath={MARKETPLACE_MANAGEMENT_PATH}
        errorMessage={t('error.notFound')}
        error={error}
      />
    );

  return (
    <>
      <ReactRouterPrompt when={preventNavigation}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive && (
            <SLRPrompt
              labels={t('preventNavigation', {
                returnObjects: true
              })}
              onCancel={onCancel}
              onConfirm={() => handleNavigate(onConfirm)}
            />
          )
        }
      </ReactRouterPrompt>
      {promptProps && <SLRPrompt {...promptProps} />}

      {showPreview && (
        <EntryPreview entry={form.getValues()} onClose={handleHidePreview} />
      )}
      <FormProvider {...form}>
        <Form noValidate>
          <Container fluid className="p-0 entry-create-update">
            <SLRPageHead
              backIcon={getIcon('fal', 'circle-chevron-left')}
              className="pt-8"
              to={
                isReviewContext
                  ? ''
                  : (state?.previousPath ?? MARKETPLACE_MANAGEMENT_PATH)
              }
              labels={t(
                `pageHead.${isReviewContext ? 'review' : isEmptyOrNull(entryFormId) ? 'create' : 'update'}`,
                { returnObjects: true }
              )}
            />

            <Container className="pt-5">
              <SLRAccordion
                {...accordionProps}
                defaultActiveKey={activeStepKey}
                onKeyUpdate={setActiveStepKey}
                content={createFormViews}
              />
              <div className="text-center py-4 fs-5 text-muted last-updated">
                {!isEmptyOrNull(entry?.updatedAt) &&
                  t('updatedAt', {
                    date: toDayMonthYear(entry?.updatedAt),
                    time: toHourAndMinute(entry?.updatedAt)
                  })}
              </div>
            </Container>

            <Actions
              status={entry?.status}
              isLoading={isCreateEntryLoading || isUpdateEntryLoading}
              reviewComment={entry?.reviewComment}
              onPreview={handleShowPreview}
              onSave={
                useValidatingSave
                  ? form.handleSubmit(() => handleSaveEntry())
                  : () => handleSaveEntry()
              }
              onSubmit={() =>
                handleSaveEntry(
                  form.handleSubmit(({ id }) => handleSubmitEntry(id))
                )
              }
              onPublish={form.handleSubmit(() =>
                handleSaveEntry(handlePublishEntry)
              )}
              onUnPublish={form.handleSubmit(() =>
                handleSaveEntry(handleUnPublishEntry)
              )}
              onDelete={handleDeleteEntry}
              onCancel={() => handleNavigate()}
            />
          </Container>
        </Form>
      </FormProvider>
    </>
  );
};
