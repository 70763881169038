/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Declarations } from 'configs';
import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DeclarationItem } from './DeclarationItem';

import './DeclarationsModal.scss';

export const DeclarationsModal: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.detail.declarations.modal'
  });

  return (
    <div className="declarations-modal">
      <h3 className="mb-3">{t('subtitle')}</h3>
      <div className="mb-5">{t('info')}</div>
      <Container>
        <Row className="mx-2 mx-sm-4">
          {Object.values(Declarations)?.map((declaration) => (
            <Col xs="12" key={declaration} className="mb-4">
              <DeclarationItem
                declaration={declaration}
                className="align-items-center"
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
