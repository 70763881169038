/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { Ownership } from '@SLR/media-service-sdk';
import {
  SLRFormInfo,
  SLRMediaForm,
  SLRMediaFormIcons,
  SLRMediaSizes,
  getIcon,
  isEmptyOrNull
} from '@SLR/shared-library';
import { FC } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { EntryInput, MEDIA_MAX_COUNT } from '../..';

const icons: SLRMediaFormIcons = {
  dropIcon: getIcon('fal', 'image'),
  addIcon: getIcon('fal', 'plus'),
  errorIcon: getIcon('fal', 'image-slash'),
  videoIcon: getIcon('fal', 'video'),
  reloadIcon: getIcon('fal', 'rotate-right'),
  deleteIcon: getIcon('fal', 'trash-can'),
  cancelIcon: getIcon('fal', 'xmark'),
  dragIcon: getIcon('fal', 'grip-dots-vertical')
};

export const Media: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.media'
  });

  const {
    formState: { errors },
    getValues,
    watch,
    control
  } = useFormContext<EntryInput>();

  const hasPreviewImageId = !isEmptyOrNull(watch('previewImageId'));
  const mediaCount = watch('media')?.length ?? 0;

  return (
    <Row className="media-forms gap-3">
      <Col xs="12" className={errors.previewImageId ? 'text-danger' : ''}>
        <Form.Label htmlFor="media.previewImage">
          {t('previewImage.title', {
            count: hasPreviewImageId ? 1 : 0,
            maxCount: 1
          })}
        </Form.Label>
        <SLRFormInfo text={t('previewImage.info')} />
        <SLRFormInfo className="mb-3" text={t('previewImage.additionalInfo')} />
        <SLRMediaForm
          id="media.previewImage"
          name="previewImageId"
          mediaItemNameSuffix={t('previewImage.formNameSuffix')}
          allowedMaxMediaCount={1}
          control={control}
          icons={icons}
          mediaOwner={{
            organizationId: getValues('organizationId') ?? undefined,
            owner: Ownership.Organization
          }}
          mediaSize={SLRMediaSizes.Small}
        />
        <SLRFormInfo
          isInvalid={!!errors.previewImageId}
          text={errors?.previewImageId?.message}
        />
      </Col>
      <Col xs="12" className={errors.media ? 'text-danger' : ''}>
        <Form.Label htmlFor="media.images">
          {t('media.title', {
            count: mediaCount,
            maxCount: MEDIA_MAX_COUNT
          })}
        </Form.Label>
        <SLRFormInfo text={t('media.info')} />
        <SLRFormInfo className="mb-3" text={t('media.additionalInfo')} />
        <SLRFormInfo className="form-label" text={t('media.subTitle')} />
        <SLRMediaForm
          id="media.images"
          name="media"
          mediaItemNameSuffix={t('media.formNameSuffix')}
          allowedMaxMediaCount={MEDIA_MAX_COUNT}
          allowVideo
          control={control}
          icons={icons}
          mediaOwner={{
            organizationId: getValues('organizationId') ?? undefined,
            owner: Ownership.Organization
          }}
          mediaSize={SLRMediaSizes.Small}
        />
      </Col>
    </Row>
  );
};
