/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRMediaSizes,
  isEmptyOrNull,
  useGetMediaItem,
  useImageLoading
} from '@SLR/shared-library';
import { defaultEntryImage } from 'assets';
import { FC } from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export type EntryImageProps = {
  entryName?: string | null;
  imageId?: string | null;
};

export const EntryImage: FC<EntryImageProps> = ({
  entryName,
  imageId = ''
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.item.image'
  });

  const {
    data: entryImage,
    isFetching,
    isError
  } = useGetMediaItem(imageId, SLRMediaSizes.Small);

  const { isImageLoading, isImageError } = useImageLoading(
    entryImage?.url,
    isFetching
  );

  const isLoadingState = isFetching || isImageLoading;
  const isErrorState = isError || isImageError || isEmptyOrNull(imageId);

  return (
    <>
      <Placeholder
        variant="top"
        aria-label={t('pictureLoadingLabel', { solution: entryName })}
        aria-hidden
        className={`${isLoadingState ? 'd-inline' : 'd-none'} entry-image`}
        animation="wave"
      />

      <Card.Img
        variant="top"
        src={entryImage?.url}
        alt={entryImage?.altText ?? t('pictureAlt', { solution: entryName })}
        className={`${!isLoadingState && !isErrorState ? 'd-inline' : 'd-none'} entry-image`}
      />

      <Card.Img
        variant="top"
        src={defaultEntryImage}
        alt={t('pictureFallBackAlt', { solution: entryName })}
        aria-hidden={!isErrorState}
        className={`${isErrorState ? 'd-inline' : 'd-none'} entry-no-image`}
      />
    </>
  );
};
