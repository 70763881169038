/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import { DeclarationReferences, Declarations } from 'configs';
import { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './DeclarationsForm.scss';

export type DeclarationsFormProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
};

export const DeclarationsForm = <T extends FieldValues>({
  control,
  name
}: DeclarationsFormProps<T>) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'marketplace.entry.create.description.declarations.options'
  });

  const {
    field: { value, onChange }
  } = useController({
    name,
    control
  });

  const handleChange = useCallback(
    (option: string) => {
      onChange({
        ...value,
        [option]: !value[option]
      });
      return;
    },
    [onChange, value]
  );

  return (
    <Row className="mt-3 align-items-end declarations-form">
      {Object.values(Declarations).map((option) => (
        <Col xs={12} key={`${name}-${option}`}>
          <Form.Check
            id={`${name}-${option}`}
            className="m-0 title"
            type="checkbox"
            checked={value[option]}
            label={t(`${option}.label`)}
            onChange={() => {
              handleChange(option);
            }}
          />
          <div className="description">
            <Trans
              t={t}
              i18nKey={`${option}.description`}
              components={[
                <Link
                  to={DeclarationReferences[option]}
                  target="_blank"
                  key={option}
                />
              ]}
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};
