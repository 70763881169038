/*
 * Copyright (C) Fraunhofer IESE 2021-2024 - Mher Ter-Tovmasyan, Emily Calvet,
 * Milad Chatrangoon, Steffen Hupp, Philipp Ewen, Pedram (Majid) Jokar, Bestin John
 *
 * SPDX-License-Identifier: AGPL-3.0-or-later
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <https://www.gnu.org/licenses/>.
 */

import {
  SLRLabelIcon,
  SLRMediaSizes,
  SLRModal,
  SLRNavBar,
  SLRProfileDropdown,
  getIcon,
  hasValue,
  useAuth,
  useGetCurrentUserProfile,
  useGetMediaItem,
  useWindowSize
} from '@SLR/shared-library';
import {
  ManagementLogo,
  MarketplaceLogo,
  ReviewLogo,
  defaultPersonLogo
} from 'assets';
import {
  MARKETPLACE_MANAGEMENT_PATH,
  MARKETPLACE_PATH,
  MARKETPLACE_REVIEW_PATH,
  createUserPortalUrl
} from 'configs';
import { orderBy } from 'lodash';
import { useMarketplace, useOrganization } from 'providers';
import { FC, useCallback, useMemo, useState } from 'react';
import { Button, ListGroup, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './NavBar.scss';

export const NavBar: FC = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const { hasAccessByRole, isReviewContext, isManagementContext } =
    useMarketplace();
  const {
    availableOrganizations,
    selectedOrganization,
    isSolutionProvider,
    selectOrganization
  } = useOrganization();

  const { t } = useTranslation();

  const { width } = useWindowSize();

  const { data: user, isPending: isUserLoading } = useGetCurrentUserProfile();
  const { data: userPicture, isFetching: isUserPictureLoading } =
    useGetMediaItem(user?.pictureId, SLRMediaSizes.Small);

  const isLoading = isAuthenticated && (isUserLoading || isUserPictureLoading);

  const [showModal, setShowModal] = useState(false);

  const handleSelectOrganization = useCallback(
    (organizationId: string) => {
      selectOrganization(organizationId);
      setShowModal(false);
    },
    [selectOrganization]
  );

  const appURL: string = useMemo(() => {
    if (isManagementContext) return MARKETPLACE_MANAGEMENT_PATH;
    else if (isReviewContext) return MARKETPLACE_REVIEW_PATH;
    return MARKETPLACE_PATH;
  }, [isManagementContext, isReviewContext]);

  const appLogo = useMemo(() => {
    if (isManagementContext) return <ManagementLogo />;
    else if (isReviewContext) return <ReviewLogo />;
    else return <MarketplaceLogo />;
  }, [isManagementContext, isReviewContext]);

  return (
    <>
      <SLRNavBar
        appURL={appURL}
        appLogo={appLogo}
        profileDropdown={
          isAuthenticated ? (
            <SLRProfileDropdown
              keepNamesVisible={width > 480}
              isLoading={isLoading}
              profilePicture={userPicture?.url}
              profilePictureFallback={defaultPersonLogo}
              lastName={user?.lastName}
              firstName={user?.firstName}
              organizationName={
                selectedOrganization
                  ? t('navBar.for', {
                      organization: selectedOrganization.organizationName
                    })
                  : ''
              }
            >
              <NavDropdown.Item href={createUserPortalUrl()}>
                <SLRLabelIcon
                  icon={getIcon('fal', 'external-link')}
                  label={t('navBar.profile')}
                />
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <>
                {hasValue(availableOrganizations) && (
                  <>
                    {availableOrganizations.length > 1 && (
                      <>
                        <NavDropdown.Item onClick={() => setShowModal(true)}>
                          <SLRLabelIcon
                            icon={getIcon('fal', 'repeat')}
                            label={t('navBar.switchOrganization')}
                          />
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>
                    )}

                    {isSolutionProvider && (
                      <>
                        <NavDropdown.Item
                          as={Link}
                          to={
                            isManagementContext
                              ? MARKETPLACE_PATH
                              : MARKETPLACE_MANAGEMENT_PATH
                          }
                        >
                          <SLRLabelIcon
                            icon={
                              isManagementContext
                                ? getIcon('fal', 'shop')
                                : getIcon('fal', 'gear')
                            }
                            label={t(
                              isManagementContext
                                ? 'navBar.toMarketplace'
                                : 'navBar.toManagement'
                            )}
                          />
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                      </>
                    )}
                  </>
                )}
                <>
                  {hasAccessByRole && (
                    <>
                      <NavDropdown.Item
                        as={Link}
                        to={
                          isReviewContext
                            ? MARKETPLACE_PATH
                            : MARKETPLACE_REVIEW_PATH
                        }
                      >
                        <SLRLabelIcon
                          icon={
                            isReviewContext
                              ? getIcon('fal', 'shop')
                              : getIcon('fal', 'gear')
                          }
                          label={t(
                            isReviewContext
                              ? 'navBar.toMarketplace'
                              : 'navBar.toReview'
                          )}
                        />
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                    </>
                  )}
                </>
              </>
              <NavDropdown.Item onClick={() => logout()}>
                <SLRLabelIcon
                  icon={getIcon('fal', 'sign-out-alt')}
                  label={t('navBar.logout')}
                />
              </NavDropdown.Item>
            </SLRProfileDropdown>
          ) : (
            <Button
              variant="outline-light"
              size="lg"
              onClick={() => login(window.location.href)}
            >
              {t('navBar.login')}
            </Button>
          )
        }
      />
      {showModal && (
        <SLRModal
          isOpen
          title={t('navBar.switchOrganization')}
          className="organization-modal"
          onClose={() => setShowModal(false)}
          onHide={() => setShowModal(false)}
        >
          <ListGroup className="list">
            {orderBy(
              availableOrganizations,
              ['organizationName'],
              ['asc']
            )?.map((o) => (
              <ListGroup.Item
                key={o.organizationId}
                className="cursor-pointer"
                active={
                  o.organizationId === selectedOrganization?.organizationId
                }
                eventKey={o.organizationId}
                onClick={() => handleSelectOrganization(o.organizationId)}
              >
                {o.organizationName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </SLRModal>
      )}
    </>
  );
};
